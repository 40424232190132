<template>
  <div class="mb-3">
    <b-card title="User Permissions">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-row>
        <b-col>
          <b-form-group label="Permissions" label-for="ddlUserPermissons">
            <treeselect
              instanceId="ddlUserPermissons"
              v-model="permissions"
              :multiple="true"
              :options="allowedPermissions"
              valueFormat="object"
              value-consists-of="LEAF_PRIORITY"
              :class="
                inputValidations.chkState('permissions', $v) == false
                  ? 'is-invalid'
                  : ''
              "
              :disabled="!canEdit"
            >
              <label
                slot="option-label"
                slot-scope="{ node, labelClassName, countClassName }"
                :class="labelClassName"
              >
                {{ node.label }}
                <span v-if="node.raw.description" :class="countClassName"
                  >({{ node.raw.description }})</span
                >
              </label>
            </treeselect>
            <b-form-invalid-feedback
              :state="inputValidations.chkState('permissions', $v)"
              >Minimum of 1 permission required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!canAddImagePermissions">
        <b-col>
          <b-form-group
            label="Image Permissions"
            label-for="ddlCustomerImagePermissions"
          >
            <treeselect
              instanceId="ddlCustomerImagePermissions"
              v-model="userImagePermissions"
              :multiple="true"
              :options="imagePermissionOptions"
              valueFormat="id"
              :disabled="!canEdit"
              :class="
                inputValidations.chkState('userImagePermissions', $v) == false
                  ? 'is-invalid'
                  : ''
              "
            ></treeselect>
            <b-form-invalid-feedback
              :state="inputValidations.chkState('userImagePermissions', $v)"
              >Minimum of 1 permission required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="canEdit">
        <b-col>
          <b-button
            variant="primary"
            class="btn-block-xs-only"
            @click="saveUser()"
            >Save User Permissions</b-button
          >
          <b-button
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="loadUserPermissions()"
            >Reset User Permissions</b-button
          >
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="alert alert-warning" role="alert">
            You are unable to edit your own Permissions, if you feel this
            information is incorrect, please contact your company administrator.
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactory } from "@/services/RepositoryFactory";
const UserRepository = RepositoryFactory.get("users");

import { required, requiredIf } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";

import { handleRequestError } from "@/shared/utils/response-error-handler";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";

import { isCustomerAdmin } from "@/shared/utils/permissions";

//isCustomerAdmin

export default {
  props: ["userId", "selectedUser", "customerId"],
  components: {
    Treeselect,
    Loading,
  },
  data() {
    return {
      permissions: [],
      allowedPermissions: [],
      userImagePermissions: [],
      isLoading: false,
      inputValidations: inputValidations,
    };
  },
  async mounted() {
    await this.loadUserPermissions();
  },
  methods: {
    ...mapActions("users", [
      "setIsPermissionsLoaded",
      "setSelectedUserDocTypes",
    ]),
    async loadUserPermissions() {
      this.isLoading = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        userId: this.userId,
      };

      try {
        let userRepoPromise = UserRepository.getUserPermissions(requestData);
        let response = await userRepoPromise;

        this.permissions = this.userPermissions(
          response.data.currentPermissions
        );
        this.allowedPermissions = this.allowedUserPermissions(
          response.data.allowedPermissions
        );

        this.userImagePermissions = response.data.docTypes;

        this.setSelectedUserDocTypes(response.data.docTypes);
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
        this.setIsPermissionsLoaded(true);
      }
    },
    async saveUser() {
      this.isLoading = true;

      try {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.isLoading = true;

          var requestData = {
            jwtToken: this.$store.state.user.jwtToken,
            userId: this.userId,
            data: {
              userId: this.userId,
              permissions: this.translatedPermissions,
              allowedDocTypes: this.userImagePermissions,
            },
          };

          var response = await UserRepository.updateUserPermissions(
            requestData
          );

          this.setSelectedUserDocTypes(this.userImagePermissions);

          this.isLoading = false;

          this.$emit("goToTab", 2);

          this.$snotify.success(
            `User Permissions updated successfully.`,
            "Success",
            {
              timeout: 1500,
              showProgressBar: true,
            }
          );
        }
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },
    userPermissions(permissions) {
      let arrUserPermissions = [];

      Object.keys(permissions).forEach((key) => {
        permissions[key].forEach((subPermission) => {
          arrUserPermissions.push({
            id: `${key}-${subPermission}`,
            label: `${key} - ${subPermission}`,
          });
        });
      });

      return arrUserPermissions;
    },
    allowedUserPermissions(allowedPermissions) {
      var arrAllowedPermissions = [];

      Object.keys(allowedPermissions).forEach((key) => {
        var arrPermissions = [];
        allowedPermissions[key].forEach((permission) => {
          arrPermissions.push({
            id: `${key}-${permission}`,
            label: `${key} - ${permission}`,
          });
        });

        arrAllowedPermissions.push({
          id: `${key}-all`,
          label: key,
          children: arrPermissions,
        });
      });
      return arrAllowedPermissions;
    },
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("users", ["getGlobalUserDocTypes"]),
    translatedPermissions() {
      var translatedPermissions = {};
      this.permissions.forEach((permission) => {
        // if permission has children, that means they picked a
        // root level option in the tree list so we need to loop through
        // it and get all children
        if (permission.children) {
          var childrenArray = [];
          permission.children.forEach((child) => {
            // each id has to be unique so I made them 'permission-subpermission'
            // split it to get the sub permission and push it into an array
            var childSplit = child.id.split("-");
            childrenArray.push(childSplit[1]);
          });

          //add property to object with key set to permission
          translatedPermissions[permission.label] = childrenArray;
        } else {
          //var subPermissionArr = [];
          // each id has to be unique so I made them 'permission-subpermission'
          // split it to get the sub permission and push it into an object
          var splitPermission = permission.id.split("-");

          //if object already contains permission
          // push the sub permission to the corresponding array
          if (translatedPermissions[splitPermission[0]]) {
            translatedPermissions[splitPermission[0]].push(splitPermission[1]);
          } else {
            translatedPermissions[splitPermission[0]] = [splitPermission[1]];
          }
        }
      });
      return translatedPermissions;
    },
    imagePermissionOptions() {
      const imagePermissions = this.getGlobalUserDocTypes || [];

      return imagePermissions.map((imagePermission) => ({
        id: imagePermission.docType,
        label: imagePermission.name,
      }));
    },
    canAddImagePermissions() {
      var canAddImages = false;
      this.permissions.forEach((cp) => {
        // id 5 is image permissions brosuf
        if (cp.id == "ImagePermissions-View") canAddImages = true;
      });

      if (this.theUser) {
        if (this.theUser.id == this.loggedInUserId) canAddImages = false;
      }
      return !canAddImages;
    },
    canEdit() {
      return this.userId != this.oidcUser.sub;
    },
  },
  validations: {
    permissions: {
      required,
    },
    userImagePermissions: {
      required: requiredIf(function () {
        return !this.canAddImagePermissions;
      }),
    },
  },
  destroyed() {
    this.setIsPermissionsLoaded(false);
  },
};
</script>
