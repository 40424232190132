<template>
  <div class="container vld-parent">
    <b-row>
      <b-card class="w-100">
        <b-row>
          <b-col class="mb-2">
            <b-card-title class="d-inline">{{
              userId ? "Edit User" : "Create New User"
            }}</b-card-title>

            <b-button
              @click="goBackToUsers"
              variant="outline-primary"
              size="sm"
              class="float-right btn-block-xs-only  mt-2 mt-md-0"
              >&lt; Back To All Users</b-button
            >
          </b-col>
        </b-row>

        <b-row v-if="userToEdit">
          <b-col cols="12">
            <p>
              <b
                >{{ userToEdit.firstName }} {{ userToEdit.lastName }} ({{
                  userToEdit.emailAddress
                }})</b
              >
              <br />
              <b>Id: </b>{{ userToEdit.id }}
              <br />
              <b>Customer:</b> {{ userToEdit.customerName }} (id:
              {{ userToEdit.customerId }})
            </p>
          </b-col>
        </b-row>

        <b-tabs
          content-class="mt-3"
          v-model="tabIndex"
          @activate-tab="tabSwitched"
        >
          <b-tab title="User Info">
            <tf-user-information
              :user="userToEdit"
              :isLoading="isLoading"
              v-if="userId"
              v-on:saveUser="saveUser($event, false)"
              v-on:resetUser="loadUser()"
            ></tf-user-information>
            <tf-new-user-information
              v-else
              :isLoading="isLoading"
              v-on:createUser="createUser($event)"
            ></tf-new-user-information>
            <!-- {{ userInfoDirty }}
            <b-alert variant="info" v-model="userInfoDirty"
              >You changed something</b-alert
            > -->
          </b-tab>
          <b-tab title="Permissions" lazy v-if="userToEdit">
            <tf-user-permissions
              :userId="userId"
              :selectedUser="userToEdit"
              v-on:goToTab="tabIndex = $event"
              v-if="userId && userToEdit"
            ></tf-user-permissions
          ></b-tab>
          <b-tab title="Data Restrictions" lazy v-if="userToEdit">
            <tf-user-data-restrictions
              :loggedInUserId="loggedInUserId"
              :selectedUser="userToEdit"
              v-on:goToTab="tabIndex = $event"
              v-if="userToEdit"
            ></tf-user-data-restrictions>
          </b-tab>

          <b-tab v-if="showFinalizeTab && userToEdit">
            <template v-slot:title>
              <b
                >Activate User
                <i
                  class="fa fa-question-circle text-primary bottom-align-text ml-2"
                  v-b-popover.hover="finalizePopOverText"
                  title="Activate User"
                ></i>
              </b>
            </template>

            <tf-user-activation
              :isLoading="isLoading"
              v-on:activateUser="activateUser()"
            ></tf-user-activation>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import { handleRequestErrorNew } from "@/shared/utils/response-error-handler";
import { mapActions, mapGetters, mapState } from "vuex";
import TfUserInformation from "@/components/users/UserInformation";
import TfNewUserInformation from "@/components/users/NewUserInformation";
import TfUserDataRestrictions from "@/components/users/UserDataRestrictions";
import TfUserPermissions from "@/components/users/UserPermissions";
import TfUserActivation from "@/components/users/UserActivation";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const UserRepository = RepositoryFactory.get("users");

import { handleRequestError } from "@/shared/utils/response-error-handler";

const DocTypesRepository = RepositoryFactory.get("docTypesRepository");

export default {
  props: [],
	
  components: {
    TfNewUserInformation,
    TfUserInformation,
    TfUserDataRestrictions,
    TfUserPermissions,
    TfUserActivation
  },

  data() {
    return {
      tabIndex: 0,
      userToEdit: null,
      isLoading: false,
      userInfoDirty: false
    };
  },

  async mounted() {
    if (this.userId) {
      await this.loadUser();
    }
  },

  methods: {
    ...mapActions("users", [
      "setGlobalUserDocTypes",
      "setSelectedUserDocTypes"
    ]),

    goBackToUsers() {
      this.$router.push({
        name: "external-admin-users"
      });
    },

    async loadUser() {
      this.isLoading = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        userId: this.userId
      };

      try {
        let response = await UserRepository.getUser(requestData);

        this.userToEdit = response.data;

        await this.loadGlobalDocTypes(this.userToEdit.customerId);
        await this.loadUserPermissions();
      } catch (error) {
        await this.loadGlobalDocTypes(this.userToEdit.customerId);
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
      this.userInfoDirty = false;
    },
		
    async loadGlobalDocTypes(customerId) {
      try {
        let response = await DocTypesRepository.get(customerId);
        this.setGlobalUserDocTypes(response.data);
      } catch (error) {
        var errorMessage = handleRequestErrorNew(error);
        this.$snotify.error(errorMessage.title, "Error");
      }
    },

    async loadUserPermissions() {
      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        userId: this.userId
      };

      let userRepoPromise = UserRepository.getUserPermissions(requestData);
      let response = await userRepoPromise;

      this.userImagePermissions = response.data.docTypes;

      this.setSelectedUserDocTypes(response.data.docTypes);
    },

    async activateUser() {
      let tempUser = this.cleanSource(this.userToEdit); //todo: this cleansource needs to be a global function
      tempUser.isActive = true;
      await this.saveUser(tempUser, true);
    },

    async saveUser(user, isActivation) {
      try {
        this.isLoading = true;

        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          userId: user.id,
          userData: user
        };
        let result = await UserRepository.updateUser(requestData);

        this.isLoading = false;
        this.wasUpdated = true;

        let message = "User Information updated successfully.";
        if (isActivation) message = "User has been successfully activated.";

        this.$snotify.success(message, "Success", {
          timeout: 1500,
          showProgressBar: true
        });

        this.userInfoDirty = false;
        this.userToEdit = result.data;
        this.tabIndex = isActivation ? 0 : 1;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },

    async createUser(userToCreate) {
      try {
        this.isLoading = true;
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          userId: userToCreate.id,
          userData: userToCreate
        };

        var response = await UserRepository.create(requestData);

        this.isLoading = false;

        this.$snotify.success(`User successfully created.`, "Success", {
          timeout: 1500,
          showProgressBar: true
        });

        this.userToEdit = response.data;

        this.$router.push({
          name: "admin-edit-user",
          params: { id: response.data.id }
        });

        //this seems hacky, but it's the only way to get it to switch the tab since I'm not showing them until after the initial user create.
        //https://github.com/bootstrap-vue/bootstrap-vue/issues/3575
        this.$nextTick(() => {
          this.$nextTick(() => {
            requestAnimationFrame(() => {
              this.tabIndex = 1;
            });
          });
        });
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },

    cleanSource(source) {
      // using native JSON functions removes reactivity
      // so we can clone an object without mutating the original source
      return JSON.parse(JSON.stringify(source));
    },

    tabSwitched(newIndex, prevIndex, bvEvt) {
      // if (prevIndex == 0 && this.userInfoDirty) {
      //   bvEvt.preventDefault();
      // }
    }
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),

    userId() {
      return this.$route.params.id;
    },

    loggedInUserId() {
      return this.oidcUser.sub;
    },

    showFinalizeTab() {
      if (this.userToEdit) return !this.userToEdit.isActive;
      return true;
    },

    finalizePopOverText() {
      return "When you are done adding user permissions and data restrictions please finalize the user to activate and send out the welcome email.";
    }
  },

  watch: {
    async userId(newValue) {
      if (newValue != null) {
        await this.loadUser();
      }
    }
   }
};
</script>

<style scoped></style>
