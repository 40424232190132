<template>
  <div class="mb-3">
    <b-card title="User Information">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-row>
        <b-col cols="12" v-if="!checkExternalUser">
          <b-form-group label="Select Customer" label-for="msCustomer">
            <CustomersMultiSelect
              id="msCustomer"
              :class="validateState('customerId') == false ? 'is-invalid' : ''"
            ></CustomersMultiSelect>
            <b-form-invalid-feedback
              id="msCustomer-live-feedback"
              :state="validateState('customerId')"
              >Customer is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="First Name" label-for="txtCustomerName">
            <b-form-input
              id="txtFirstName"
              name="txtFirstName"
              v-model="user.firstName"
              :class="validateState('firstName') == false ? 'is-invalid' : ''"
              aria-describedby="txtFirstName-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="txtFirstName-live-feedback"
              >First name is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Last Name" label-for="txtCustomerName">
            <b-form-input
              id="txtLastName"
              v-model="user.lastName"
              :class="validateState('lastName') == false ? 'is-invalid' : ''"
              aria-describedby="txtLastName-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="txtLastName-live-feedback"
              >Last name is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Email" label-for="txtEmail">
            <b-form-input
              id="txtEmail"
              v-model.trim="user.emailAddress"
              :class="
                validateState('emailAddress') == false ? 'is-invalid' : ''
              "
              aria-describedby="txtEmail-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="txtEmail-live-feedback"
              >Valid email is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <b-form-group label="Phone Number" label-for="txtPhoneNumber">
            <masked-input
              id="txtPhoneNumber"
              class="form-control"
              placeholder="(555) 555-5555"
              v-model="user.phoneNumber"
              :class="validateState('phoneNumber') == false ? 'is-invalid' : ''"
              :guide="true"
              :mask="[
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]"
              aria-describedby="txtPhone-live-feedback"
            ></masked-input>
            <b-form-invalid-feedback id="txtPhone-live-feedback"
              >Phone number is required and should be (###)
              ###-####.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group>
            <b-form-checkbox v-model="user.customerAdmin"
              >Customer Admin?</b-form-checkbox
            >
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            class="btn-block-xs-only"
            @click="saveUser()"
            >Create User</b-button
          >

          <b-button
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="cancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import store from "@/store/store";
import { isExternalUser } from "@/shared/utils/permissions";

import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactory } from "@/services/RepositoryFactory";

const UserRepository = RepositoryFactory.get("users");

import { validationMixin } from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import inputValidations from "@/shared/utils/input-validation";
const checkValidPhoneNumber = inputValidations.checkValidPhoneNumber;

import { handleRequestError } from "@/shared/utils/response-error-handler";
import MaskedInput from "vue-text-mask";
import CustomersMultiSelect from "@/components/customers/CustomersMultiSelect";

export default {
  mixins: [validationMixin],
  props: ["userId", "customerId", "isLoading"],
  components: {
    MaskedInput,
    Loading,
    CustomersMultiSelect
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        customerId: ""
      },
      inputValidations: inputValidations
    };
  },
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      if (this.getFilterSelectedCustomer)
        this.user.customerId = this.getFilterSelectedCustomer.id;
    },
    async saveUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        //if it's an external user creating the new user, then they have to belong to their assigned customer.
        if (this.checkExternalUser)
          this.user.customerId = this.loggedInUser.customerId;
        //else customerId should be bound from the dropdown that an internal user selects.

        this.$emit("createUser", this.user);
      }
    },
    cancel() {
      this.$router.push({
        name: "external-admin-users"
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];

      return $dirty ? !$error : null;
    }
  },
  computed: {
    ...mapGetters("users", ["getFilterSelectedCustomer"]),
    ...mapGetters("user", ["loggedInUser"]),
    checkExternalUser() {
      return isExternalUser(store);
    }
  },
  watch: {
    
    getFilterSelectedCustomer(newValue, oldValue) {

      if (newValue) this.user.customerId = newValue.id;
      else this.user.customerId = null;
    }
  },
  validations: {
    user: {
      customerId: {
        required: requiredIf(function() {
          return !this.checkExternalUser;
        })
      },

      firstName: {
        required
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email
      },
      phoneNumber: {
        required,
        checkValidPhoneNumber
      }
    }
  }
};
</script>
<style scoped></style>
