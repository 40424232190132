<template>
  <div class="mb-3">
    <b-card title="User Activation">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <p>
        When you are done adding user permissions and data restrictions please
        use the button below to "Activate" the user and send out the welcome
        email.
      </p>
      <p>
        The user will not be able to sign in until you have completed this step.
      </p>
      <b-button variant="outline-success" @click="activateUser()"
        >Activate and send email</b-button
      >
    </b-card>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["isLoading"],
  components: {
    Loading
  },
  data() {
    return {};
  },
  async mounted() {},
  methods: {
    async activateUser() {
      this.$emit("activateUser");
    }
  },
  computed: {},
  watch: {}
};
</script>
<style scoped></style>
