<template>
  <div class="mb-3">
    <b-card>
      <loading
        :active.sync="isBusy"
        :is-full-page="false"
        color="#114C8E"
      ></loading>

      <b-card-title class="test">
        Current Data Restrictions
        <i
          class="fa fa-question-circle text-primary bottom-align-text"
          v-b-popover.hover.html="userDataSecurityPopoverText"
          title="Current Data Restrictions"
        ></i>
      </b-card-title>

      <b-row>
        <b-col>
          <loading
            :active.sync="isBusy"
            :is-full-page="false"
            color="#114C8E"
          ></loading>

          <b-table
            :items="stagings"
            :fields="fields"
            responsive="sm"
            striped
            outlined
            small
            :per-page="perPage"
            show-empty
          >
            <template v-slot:cell(isReadOnly)="data">
              <b-form-checkbox :disabled="true" v-model="data.item.isReadOnly">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(allowedDocTypes)="data">
              <span
                v-for="(item, index) in data.item.allowedDocTypes"
                :key="item"
              >
                {{ item }}
                <span v-if="index != data.item.allowedDocTypes.length - 1">
                  ,
                </span>
              </span>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                v-if="canEdit"
                size="sm"
                variant="danger"
                class="mb-1"
                id="btnEditUser"
                :disabled="data.item.isDeleting"
                @click="deleteRelationshipFilter(data.item)"
              >
                <i class="fa fa-trash"></i>

                <span
                  v-if="data.item.isDeleting"
                  class="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span></b-button
            ></template>

            <template v-slot:empty="scope">
              <h5 class="text-center">{{ scope.emptyText }}</h5>
            </template>
          </b-table>

          <b-pagination
            v-if="totalRecords > 0"
            size="md"
            :total-rows="totalRecords"
            v-model="currentPage"
            :per-page="perPage"
            @input="getPostData(currentPage)"
            first-number
            last-number
          >
          </b-pagination>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="canEdit">
        <b-col cols="12">
          <h4 class="card-title">
            Add Data Restrictions
            <i
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover.html="userAddDataSecurityPopoverText"
              title="Add Data Restrictions"
            ></i>
          </h4>
        </b-col>

        <b-col lg="6">
          <!-- <div class="mb-3" v-if="isCurrentRestrictionsLoaded"> -->
          <b>Divisions</b>
          <tf-division-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
            :selectMultiple="true"
            :class="chkState('selectedDivisions') == false ? 'is-invalid' : ''"
          ></tf-division-multi-select>
          <b-form-invalid-feedback :state="chkState('selectedDivisions')"
            >At least 1 Division is required.</b-form-invalid-feedback
          >
        </b-col>

        <b-col lg="6">
          <b>BillTos</b>
          <tf-bill-to-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
            v-on:billTo-selected="billToSelected"
          ></tf-bill-to-multi-select>
        </b-col>
        <b-col lg="6">
          <b>Consignees</b>
          <tf-consignee-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
          ></tf-consignee-multi-select>
        </b-col>
        <b-col lg="6">
          <b>Shippers</b>

          <tf-shipper-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
          ></tf-shipper-multi-select>
        </b-col>
        <b-col lg="6">
          <b>Suppliers</b>
          <tf-supplier-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
          ></tf-supplier-multi-select>
        </b-col>
        <b-col lg="6">
          <b>Account Of</b>
          <tf-account-of-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
          ></tf-account-of-multi-select>
        </b-col>
        <b-col lg="6">
          <tf-commodity-multi-select
            :customerId="selectedUser.customerId"
            :userId="loggedInUserId"
          ></tf-commodity-multi-select>
        </b-col>
        <b-col lg="6">
          <b>
            Subsidaries
          </b>
          <tf-subsidiaries-multi-select></tf-subsidiaries-multi-select>
        </b-col>

        <b-col
          lg="6"
          v-if="imagePermissionOptions && imagePermissionOptions.length > 0"
        >
          <b
            >Image Permissions
            <i
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover.html="imageDataSecurityPopoverText"
              title="Data Restriction image permissions"
            ></i
          ></b>
          <treeselect
            instanceId="ddlCustomerImagePermissions"
            v-model="customerImagePermissions"
            :multiple="true"
            :options="imagePermissionOptions"
            valueFormat="object"
          ></treeselect>
        </b-col>
        <b-col lg="6" class="mb-4">
          <b-form-checkbox
            id="chk-is-read-only"
            v-model="previewIsReadOnly"
            name="chk-is-read-only"
          >
            Read Only
            <i
              class="fa fa-question-circle text-primary bottom-align-text"
              v-b-popover.hover.html="readOnlyDataSecurityPopoverText"
              title="Read Only Data Restrictions"
            ></i>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="canEdit" class="mt-3">
        <b-col>
          <b-button
            variant="outline-primary"
            class="btn-block-xs-only"
            @click="preview()"
            >Preview Data Restrictions

            <span
              v-if="isPreviewLoading"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>

          <b-button
            variant="outline-danger"
            class="ml-sm-3 mt-2 mt-sm-0 btn-block-xs-only"
            @click="resetRestrictions()"
            >Reset Data Restrictions</b-button
          >
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div class="alert alert-warning" role="alert">
            You are unable to edit your own Data Restrictions, if you feel this
            information is incorrect, please contact your company administrator.
          </div>
        </b-col>
      </b-row>

      <b-row v-if="newStagings ? newStagings.length > 0 : false" class="mt-3">
        <b-col>
          <h4 class="card-title">New Data Restrictions to be created.</h4>

          <div
            class="alert alert-warning"
            role="alert"
            v-if="showDivisionOnlyWarning"
          >
            You are about to create a division only relationship. This will
            allow this user to access any data for the selected divisions
            assigned to their customer. <br />
            If this is not what you want to do, please use the reset button to
            start over.
          </div>

          <b-table
            :items="newStagings"
            :fields="fields"
            responsive="sm"
            striped
            outlined
            small
            show-empty
          >
            <template v-slot:cell(isReadOnly)="data">
              <b-form-checkbox :disabled="true" v-model="data.item.isReadOnly">
              </b-form-checkbox>
            </template>

            <template v-slot:cell(allowedDocTypes)="data">
              <span
                v-for="(item, index) in data.item.allowedDocTypes"
                :key="item"
              >
                {{ item }}
                <span v-if="index != data.item.allowedDocTypes.length - 1">
                  ,
                </span>
              </span>
            </template>

            <template v-slot:cell(actions)="data">
              <b-button
                size="sm"
                variant="danger"
                class="mb-1"
                id="btnEditUser"
                @click="deleteRelationshipPreviewFilter(data.item)"
              >
                <i class="fa fa-trash"></i> </b-button
            ></template>

            <template v-slot:empty="scope">
              <h5 class="text-center">{{ scope.emptyText }}</h5>
            </template>
          </b-table>

          <b-button
            variant="outline-primary"
            class="btn-block-xs-only"
            @click="save()"
            >Save Data Restrictions

            <span
              v-if="isSaveLoading"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import _ from "lodash";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Treeselect } from "@riophae/vue-treeselect";
import { required } from "vuelidate/lib/validators";
import TfDivisionMultiSelect from "@/components/users/DivisionMultiSelect";
import TfBillToMultiSelect from "@/components/users/BillToMultiSelect";
import TfConsigneeMultiSelect from "@/components/users/ConsigneeMultiSelect";
import TfShipperMultiSelect from "@/components/users/ShipperMultiSelect";
import TfSupplierMultiSelect from "@/components/users/SupplierMultiSelect";
import TfAccountOfMultiSelect from "@/components/users/AccountOfMultiSelect";
import TfCommodityMultiSelect from "@/components/users/CommodityMultiSelect";
import TfSubsidiariesMultiSelect from "@/components/multiselects/SubsidiariesMultiSelect";

const UserRelationshipFilters = RepositoryFactory.get(
  "userRelationshipFilters"
);

const RelationshipFiltersPreviewRepository = RepositoryFactory.get(
  "relationshipFiltersPreviewRepository"
);

export default {
  props: ["loggedInUserId", "selectedUser"],
	
  components: {
    Loading,
    TfDivisionMultiSelect,
    TfBillToMultiSelect,
    TfConsigneeMultiSelect,
    TfShipperMultiSelect,
    TfSupplierMultiSelect,
    TfAccountOfMultiSelect,
    TfCommodityMultiSelect,
    TfSubsidiariesMultiSelect,
    Treeselect
  },

  data() {
    return {
      isBusy: false,
      isCurrentRestrictionsLoaded: false,
      isBeingDeleted: false,
      isPreviewLoading: false,
      isSaveLoading: false,
      currentPage: 1,
      perPage: 10,
      stagings: [],
      totalRecords: 0,
      newStagings: [],
      previewIsReadOnly: true,
      customerImagePermissions: [],
      showDivisionOnlyWarning: false,

      fields: [
        { key: "division", label: "Source" },
        { key: "billTo", label: "BillTo" },
        { key: "consignee", label: "Consignee" },
        { key: "shipper", label: "Shipper" },
        { key: "supplier", label: "Supplier" },
        { key: "accountOf", label: "Account Of" },
        // { key: "commodityClass", label: "Commodity Class" },
        { key: "commodity", label: "Commodity" },
        { key: "subsidiary", label: "Subsidiary" },
        { key: "isReadOnly", label: "Read Only" },
        { key: "allowedDocTypes", label: "Images" },
        //{ key: "masterOrderNumber", label: "Master Order #" },
        //{ key: "pin", label: "Pin" },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  async mounted() {
    await this.getPostData(1);
  },

  methods: {
    ...mapActions("companyMultiSelects", ["clearState"]),

    chkState(val) {
      const field = this.$v[val];
      return !field.$dirty || !field.$invalid;
    },

    async getPostData(currentPage) {
      this.isBusy = true;

      let skip = (currentPage - 1) * this.perPage;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        userId: this.$route.params.id,
        skip: skip,
        take: this.perPage
      };

      try {
        UserRelationshipFilters.get(requestData).then(res => {
          this.totalRecords = res.data.totalRecords;
          this.stagings = [];
          if (res.data.results) {
            res.data.results.forEach(element => {
              element.isDeleting = false;
              this.stagings.push(element);
            });
          }          

          this.currentPage = currentPage;

          this.isCurrentRestrictionsLoaded = true;
        });
      } catch (error) {
         console.log(error);
      } finally {
        this.isBusy = false;
      }
    },

    async preview() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isPreviewLoading = true;
      this.newStagings = [];

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken, //the token of the user editing this user
        postData: {
          userId: this.$route.params.id, //this needs to be the user being edited id.
          divisions: this.getSelectedDivisions //TODO: Need to validate and make them select at least 1 division.
            ? this.getSelectedDivisions.map(a => a.source)
            : this.division,
          billTos: this.getSelectedBillTos.map(a => a.id),
          consignees: this.getSelectedConsignees.map(a => a.id),
          shippers: this.getSelectedShippers.map(a => a.id),
          suppliers: this.getSelectedSuppliers.map(a => a.id),
          accountOfs: this.getSelectedAccountOfs.map(a => a.id),
          commodities: this.getSelectedCommodities.map(a => a.commodityCode),
          isReadOnly: this.previewIsReadOnly,
          subsidiaries: this.getSelectedSubsidiaries.map(a => a.abbreviation),
          allowedDocTypes: this.customerImagePermissions.map(a => a.id)
        }
      };

      if (
        this.getSelectedBillTos.length == 0 &&
        this.getSelectedConsignees.length == 0 &&
        this.getSelectedShippers.length == 0 &&
        this.getSelectedSuppliers.length == 0 &&
        this.getSelectedAccountOfs.length == 0 &&
        this.getSelectedCommodities.length == 0 &&
        this.getSelectedSubsidiaries.length == 0
      ) {
        this.showDivisionOnlyWarning = true;
      } else {
        this.showDivisionOnlyWarning = false;
      }

      try {
        var response = await RelationshipFiltersPreviewRepository.post(
          requestData
        );

        response.data.records.forEach(element => {
          element.isDeleting = false;
          this.newStagings.push(element);
        });
      } catch (error) {
        console.log(error);
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isPreviewLoading = false;
      }
    },

    async save() {
      this.isSaveLoading = true;

      let itemsToPost = [];

      this.newStagings.forEach(s => {
        let relationship = {
          userId: this.selectedUser.id,
          division: s.division ? s.division : this.division, //TODO: Need to validate and make them select at least 1 division.
          billTo: s.billTo,
          consignee: s.consignee,
          shipper: s.shipper,
          supplier: s.supplier,
          accountOf: s.accountOf,
          commodity: s.commodity,
          subsidiary: s.subsidiary,
          isReadOnly: s.isReadOnly,
          allowedDocTypes: s.allowedDocTypes
        };
        itemsToPost.push(relationship);
      });

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken, //the token of the user editing this user
        postData: itemsToPost
      };

      try {
        await UserRelationshipFilters.post(requestData);

        this.getPostData(1);

        this.newStagings = [];
        this.resetRestrictions();

        var el = this.$el.getElementsByClassName("test")[0];
        el.scrollIntoView(true);

        this.$snotify.success(
          `User Data Restrictions added successfully.`,
          "Success",
          {
            timeout: 700,
            showProgressBar: true
          }
        );

        if (!this.selectedUser.active) this.$emit("goToTab", 3);
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isSaveLoading = false;
      }
    },

    async deleteRelationshipFilter(item) {
      item.isDeleting = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        id: item.id
      };

      try {
        await UserRelationshipFilters.delete(requestData);

        this.stagings = this.stagings.filter(w => w.id != item.id);

        this.$snotify.success(`User filter deleted successfully.`, "Success", {
          timeout: 700,
          showProgressBar: true
        });

        // await this.getPostData(this.currentPage);
        // this.totalRecords = this.stagings.length;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        item.isDeleting = false;
      }
    },

    async deleteRelationshipPreviewFilter(item) {
      item.isDeleting = true;

      this.newStagings = this.newStagings.filter(w => w.id != item.id);
    },

    resetRestrictions() {
      this.clearState();
      this.newStagings = [];
      this.customerImagePermissions = [];
      this.$v.$reset();
    },
    
    billToSelected(value) {
      this.previewIsReadOnly = false;
    }
  },
  computed: {
    ...mapGetters({
			oidcUser: "oidcStore/oidcUser",
			getSelectedUserDocTypes: "users/getSelectedUserDocTypes",
			getGlobalUserDocTypes: "users/getGlobalUserDocTypes",
			getSelectedDivisions: "companyMultiSelects/getSelectedDivisions",
			getSelectedBillTos: "companyMultiSelects/getSelectedBillTos",
			getSelectedConsignees: "companyMultiSelects/getSelectedConsignees",
			getSelectedShippers: "companyMultiSelects/getSelectedShippers",
			getSelectedSuppliers: "companyMultiSelects/getSelectedSuppliers",
			getSelectedAccountOfs: "companyMultiSelects/getSelectedAccountOfs",
			getSelectedCommodities: "companyMultiSelects/getSelectedCommodities",
			getSelectedSubsidiaries: "companyMultiSelects/getSelectedSubsidiaries"
		}),
		
		...mapState("companyMultiSelects", ["selectedDivisions"]),

    imagePermissionOptions() {
      var globalImagePermissions = [];
      if (this.getGlobalUserDocTypes) {
        globalImagePermissions = this.getGlobalUserDocTypes;
      }
      var imagePermissions = this.getSelectedUserDocTypes;

      var ip = [];

      globalImagePermissions.forEach(imagePermission => {
        var index = _.findIndex(imagePermissions, function(o) {
          return o == imagePermission.docType;
        });

        if (index > -1) {
          ip.push({
            id: imagePermission.docType,
            label: imagePermission.name
          });
        }
      });

      return ip;
    },

    userDataSecurityPopoverText() {
      return (
        "<p>This section is specific to this user. Any restrictions set here will be the only data the user is allowed to access.</p>" +
        "<p>If no restrictions are set, this user will default to the global customer restrictions.</p>" +
        "<p>For example, if a customer has 5 consignees, but this user only has the ability to view orders for 1 it would be restricted here.</p>"
      );
    },

    userAddDataSecurityPopoverText() {
      return (
        "<p>Use this section to add new Data Restrictions</p>" +
        "<p>You can add any combination of data up to your level of Data Restrictions that the Customer allows</p>" +
        "<p>For example, if a Customer has access to Consignee A and Consignee B, and you only have access to Consignee A you can only grant a user access to Consignee A.</p>"
      );
    },

    imageDataSecurityPopoverText() {
      return (
        "<p>These image permissions will be specific to the created data restrictions.</p>" +
        "<p>For example, if you add an account of record with only DR and BOL image permissions, then any order matching the record will only have access to those 2 image types.</p>"
      );
    },
		
    readOnlyDataSecurityPopoverText() {
      return (
        "<p>If a restriction is marked read only, the customer will not be able to tender orders for items matching the restriction.</p>" +
        "<p>For example, if you add an account of record, and a supplier record to the customer marked as read only, then when retrieving consignees for order tendering those records would be ignored.</p>"
      );
    },  

    canEdit() {
      return this.selectedUser.id != this.oidcUser.sub;
    }
  },

  validations: {
    selectedDivisions: {
      required
    }
  },

  destroyed() {
    this.clearState(1);
  }
};
</script>
